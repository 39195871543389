import React from 'react';
import { Link } from 'react-router-dom';

const About: React.FC = () => (
  <div className='about'>
    <h1>About Us</h1>
    <p>
      We’re a dedicated team of web developers and designers passionate about creating bespoke websites and tailored apps for small business owners. With over two decades of combined software experience and a focus on personalized service, we understand the challenges and opportunities unique to emerging businesses.
    </p>
    <p>
      Our mission is to empower business owners with custom websites and personalized tech solutions that are elegant and scalable, so you can focus on growth without limits. We take pride in our adaptive approach, ensuring each solution is aligned with your vision, audience, and goals.
    </p>

    <div className='why-us'>
      <div>
        <h2>Why Choose Us?</h2>
        <p>
          Our team is made up of experienced web developers, designers, and graphic design experts dedicated to helping small businesses succeed online.
        <br />
        <br />
          With a focus on collaboration, creativity, and innovation, we’re here to bring your vision to life and deliver a website that exceeds your expectations. Get to know our team and discover how we can help you achieve your software dreams.
        </p>
      </div>
      <img src='/media/images/custom-fit.jpg' alt='Team' />
    </div>

    <div className='our-team-container'>
      <h2>Our Team</h2>
      <p>
        Meet the team who will help take your business to the next level. We love what we do and it shows in our work. We take pride in delivering top-notch custom software that exceeds our clients’ expectations.
      </p>
      <div className='our-team'>
        <div className='team mike'>
          <img src='/media/images/mike.jpg' alt='Mike' />
            <p className='name'><b>Mike</b></p>
            <p className='team-experience'>Experience:</p>
            <p className='team-years'>14 years</p>
            <p className='team-description'>Full stack developer with a focus on API design.</p>
            <p className='team-link'>See Mike’s background here: <a href="https://mndvns.github.io/" target='_blank' rel="noopener noreferrer">https://mndvns.github.io/</a></p>
        </div>
        <div className='team andrew'>
          <img src='/media/images/andrew.jpg' alt='Andrew' />
            <p className='name'><b>Andrew</b></p>
            <p className='team-experience'>Experience:</p>
            <p className='team-years'>8 Years</p>
            <p className='team-description'>Front-end developer with back-end chops.</p>
            {/* <p className='team-link'>See a collection of Andrew's work here: <a href="https://ryandesign.co/" target='_blank' rel="noopener noreferrer">https://ryandesign.co/</a></p> */}
        </div>
        <div className='team ryan'>
          <img src='/media/images/Ryan.jpg' alt='Ryan' />
            <p className='name'><b>Ryan</b></p>
            <p className='team-experience'>Experience:</p>
            <p className='team-years'>4 Years</p>
            <p className='team-description'>Full stack developer with a specialization in front-end development and design.</p>
            <p className='team-link'>See a collection of Ryan's work here: <a href="https://ryandesign.co/" target='_blank' rel="noopener noreferrer">https://ryandesign.co/</a></p>
        </div>
      </div>
    </div>

    <div className='get-in-touch'>
      <h2>Get in Touch</h2>
      <p>
        Ready to take your business to the next level with a bespoke website? We’d love to hear from you! Contact us today to discuss your project, ask questions, or learn more about our services. Our team is here to help you achieve your web goals and grow your business online.
      </p>
      <p>
        Let’s create something remarkable together. Reach out, and let’s start building a web solution that fits you like a tailored suit.
      </p>
      <div>
        <Link to="/contact" className='button'>Contact Us</Link>
      </div>
    </div>
  </div>
);

export default About;
