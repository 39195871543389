import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Contact: React.FC = () => (
  <div className='contact'>
    <h1>Contact Us</h1>
    <div className='flex align-center contact-description'>
      <div>
        <h2 style={{ textAlign: "start" }}>Don't Be Shy!</h2>
        <p>
          Ready to discuss your project? We are ready to help! Whether you have a clear idea or just want to explore the possibilities, we've got you covered with tech that stands out and grows with your business.
          <br />
          <br />
          Let’s create something remarkable together. Reach out, and let’s start building a software experience that’s one of a kind!
        </p>
      </div>
      <img src='/media/images/phone-1.jpg' alt='Contact' />
    </div>
    <div className='contact-info flex align-center'>
      <div>
        <FaEnvelope /><strong>Email:</strong> bespokewebservices.info@gmail.com <br />
      </div>
      <div>
        <FaPhone /><strong>Phone:</strong> (913) 703-3408
      </div>
    </div>
  </div>
);

export default Contact;
