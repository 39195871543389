import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      <div className='copyright'>
        <p>&copy;Bespoke Web Services | {(new Date().getFullYear())} | All Rights Reserved</p>
      </div>
      <div className='footer-links'>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
    </div>
  )
}

export default Footer