import React from 'react';
import { Link } from 'react-router-dom';
import { MdComputer } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { CiCreditCard1 } from "react-icons/ci";
import { IoImagesOutline } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
// import { GiCircleClaws } from "react-icons/gi";

const Home: React.FC = () => (
  <div className='home'>
    <h1><span>Bespoke</span> Web Services</h1>
    <h3>Tailored Technology to Suit Your Needs</h3>
    <div className='flex'>
      <div>
        <h2>Want More Business?</h2>
        <p>
          Our goal is to provide you with bespoke, handcrafted software that fits you like a tailored suit. We specialize in web services designed specifically for small business owners and individuals. Maybe you have an idea that would take your startup to the next level, but you're unsure how to get there because the software doesn't exist. We can help you create this solution, ensuring that it reflects your unique brand, connects with your audience, and drives results.
        </p>
        <Link className='button get-started' to="/contact">Get Started</Link>
        <Link className='button-alt' to="/about">Learn More</Link>
        {/* <div className='light-circle'>Research shows that having a strong online presence is one of the most important factors to starting a successful business in {(new Date().getFullYear())}!</div> */}
      </div>
      <div className='center home-img-1'>
        <img src='/media/images/suits.jpg' alt='Small Business Owner' />
      </div>
    </div>
    <div className='flex align-center'>
      <img className='revenue-img' src='/media/images/money-1.jpg' alt='Revenue' />
      <div>
        <h2>Increase Your Revenue</h2>
        <p className='light-bg'>
          Having a strong online presence can be a gamechanger when it comes to growing your business. With a custom website from  Bespoke Web Services, you can stand out from the competition, attract new customers, and increase your revenue. We have helped countless businesses realize their vision with a revitalized web presence, and we are ready to make the same happen for you! Explore our services and discover how a bespoke website can take your business to the next level!
        </p>
      </div>
    </div>

    <h2 style={{ textAlign: "center" }}>Our Services</h2>
    <div className='our-services'>
      <div className='services'>
        <div>
          <div className='icon-circle'>
            <MdComputer />
          </div>
          <b><p>Custom Web Design</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <IoPhonePortraitOutline />
          </div>
          <b><p>Responsive Development</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <MdOutlineScreenSearchDesktop />
          </div>
          <b><p>SEO Optimization</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <CiCreditCard1 />
          </div>
          <b><p>E-Commerce Solutions</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <IoImagesOutline />
          </div>
          <b><p>Content Management</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <IoCheckmarkDoneSharp />
          </div>
          <b><p>Custom Software</p></b>
        </div>
        <p>
          Whether you’re looking to refresh your current website or build a new software service from scratch, we’re here to help. Let’s work together to create a solution that sets your business apart and drives real results.
        </p>
      </div>
    </div>

    <div className='flex'>
      <div className='process'>
        <h2>Our Process</h2>
        <p>
          Our process is crafted to be efficient, effective, and enjoyable. We take the time to learn what your needs are, and to understand your vision. If we can accomplish your goals, then we will quote you an all-inclusive rate and get to work! At Bespoke Web Services, we believe in a transparent and collaborative process to ensure your website meets your needs and exceeds your expectations. From the initial consultation to the final launch, we work closely with you to create a software solution that reflects your brand, connects with your audience, and drives results.
        </p>
        <p>
           As a fully remote team, we offer enhanced flexibility, allowing us to dive deep into understanding your business, goals, and challenges without adding travel constraints and expenses. This enables us to create an affordable web experience that’s truly unique to your brand. Together, let’s bring your vision to life and build something one of a kind!
        </p>
      </div>
      <img className='process-img' src='/media/images/tailor.jpg' alt='Process' />
    </div>

    <div className='get-in-touch'>
      <div>
        <h2>Get in Touch</h2>
        <img src='/media/images/phone-3.jpg' alt='Contact' />
      </div>
      <div>
        <p>
          Ready to take your business to the next level with a bespoke website or custom app? We’d love to hear from you! Contact us today to
          discuss your project, ask questions, or learn more about our services. Our team is here to help you achieve your technology
          goals and grow your business.
        </p>
        <p>
          Don't hesitate to get in touch with our friendly team. We offer a free initial consultation to discuss your project and determine how we can help you achieve your goals. Looking forward to hearing from you!
        </p>
        <Link to="/contact" className='button' style={{ marginRight: "2rem" }}>Get A Quote</Link>
        <Link className='button-alt' to="/about">Learn More</Link>
      </div>
    </div>

    {/* <div className='checkmark'>
      <GiCircleClaws />
    </div> */}
  </div>
);

export default Home;
